import {useGet, usePost} from "../../../httpClient/useAuthClient";
import {useState} from "react";
import {AUTH_ENTRYPOINT} from "../../../routing/api-routes";
import FornitoreObject from "../../../models/Fornitore/FornitoreObject";
import {toastOnError, toastOnSuccess} from "../../../views/avvocato/Documentale/MotoreComposizioneDocumento/toastUtils";
import axios from "axios";

export interface FornitoreDTO {
    id: string;
    nome: string;
    logoPath?: string
    faviconPath?: string
    styles: {key: string, value: string};
}
interface FornitoreInputDTO {
    nome: string;
    url?: string;
    logoPath?: string;
    permessi?: string[];
    s3Bucket?: string;
    s3BucketUrl?: string;
    cognitoUserPoolId?: string;
    isDefault?: boolean;
    faviconPath?: string;
    testoEmailRegistrazione?: string;
}

const getFornitoriRoute = () =>AUTH_ENTRYPOINT +  "/fornitori";
const postFornitoreRoute = () =>AUTH_ENTRYPOINT +  "/fornitori";
const getFornitoreRequestRoute = () => AUTH_ENTRYPOINT + "/fornitori/request"
export const useGetFornitori = () => {

    const [fornitori, setFornitori] = useState<FornitoreDTO[]>([])
    const { get } = useGet();

    const getFornitori = () => get(getFornitoriRoute())
        .then((response) => {
            if (Array.isArray(response.data)) {
                setFornitori(response.data);
            } else {
                console.error('La risposta non è un array:', response.data);
            }
            return response;
        });

    return { getFornitori, fornitori, setFornitori};
};





export const usePostFornitore = () => {
    const { post } = usePost();

    const postFornitore = (fornitoreData: FornitoreObject) => {
        const nuovoFornitore = new FornitoreObject(fornitoreData);

        return post(postFornitoreRoute(), nuovoFornitore)
            .then(response => {
                const data = response.data as FornitoreInputDTO;
                toastOnSuccess('Creato correttamente');
                return response;
            })
            .catch(error => {
                toastOnError('Si è verificato un errore nella creazione');
                throw error;
            });
    };

    return { postFornitore };
};

export const useGetFornitoriRequest = () => {

    const [fornitoriRequest, setFornitoriRequest] = useState<FornitoreDTO | undefined>()


    const getFornitoriRequest = () => axios.get(getFornitoreRequestRoute())
        .then((response) => {
            setFornitoriRequest(response.data);
            return response;
        });

    return { getFornitoriRequest, fornitoriRequest};
};

