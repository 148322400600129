// cra imports
import React, {lazy, Suspense, useEffect} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals.js';

// import redux requirements
// import html head tags requirements
// import multi language
// import routing modules
import Loading from './components/loading/Loading';

// import routes
// import toastify for notification
import 'react-toastify/dist/ReactToastify.css';

// mock server register for demo
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import './css/bootstrap.min.css'
import './css/OverlayScrollbars.min.css'
import './css/plyr.css'
import './css/styles.css'
import {useGetFornitoriRequest} from "./api/admin/fornitore/FornitoreApi";
const Main = lazy(() => import('./Main.tsx'));

export const fornitoreLogoLocalStorageKey = "fornitoreLogo"
export const fornitoreNomeLocalStorageKey = "fornitoreNome"
export const fornitoreFaviconLocalStorageKey = "fornitoreFavicon"
export const fornitorePermessiLocalStorageKey = "fornitorePermessi"

const EntryPoint = () => {
    const {getFornitoriRequest, fornitoriRequest} = useGetFornitoriRequest()

    useEffect(() => {
        getFornitoriRequest()
            .then(response => {
                if (response && response.data && response.data.styles) {
                    updateStyleInCSS(response.data.styles);
                }
                localStorage.setItem(fornitoreLogoLocalStorageKey, response.data.logoPath)
                localStorage.setItem(fornitoreFaviconLocalStorageKey, response.data.faviconPath)
                localStorage.setItem(fornitoreNomeLocalStorageKey, response.data.nome)
            })
            .catch(error => {
                console.error("Errore la chiamata API:", error);
            });
    }, []);

    const updateStyleInCSS = (newStyle) => {
        if (newStyle !== undefined && newStyle!==null) {
            Object.keys(newStyle).forEach(property => {
                document.documentElement.style.setProperty(`${property}`, newStyle[property]);
            });
        }
    };

    if(fornitoriRequest === undefined){
        return <Loading></Loading>
    }
    return <Suspense fallback={<Loading></Loading>}><Main></Main></Suspense>

}



ReactDOM.render(<EntryPoint/>, document.getElementById('root'));


serviceWorkerRegistration.unregister();
/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
